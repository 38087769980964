document.addEventListener('DOMContentLoaded', function() {
    // Remove Items
    $(".btn-delete").click(function() {
        var productId = $(this).attr("data-product-id");
        var userGuid = $(this).attr("data-user-id");

        $.ajax({
            type: "DELETE",
            url: "/saveditemsapi/SavedItemsAPI/Delete?productid=" + productId + "&userid=" + userGuid,
            dataType: "html",
            contentType: "application/json; charset=utf-8",
            success: function(data) {
                $('.tooltip').remove();
                $("." + productId).remove();
            },
            error: function(data) {
                $('.tooltip').remove();
            }
        });
    })

    // Remove Documents
    $(".btn-document-delete").click(function() {

        var documentName = $(this).attr("data-document-name");
        var documentPath = $(this).attr("data-document-path");
        var userGuid = $(this).attr("data-user-id");
        var productId = $(this).attr("data-product-id");
        var productType = $(this).attr("data-product-type");
        var documentType = $(this).attr("data-document-type");

        $.ajax({
            type: "DELETE",
            url: "/saveddocumentsapi/SavedDocumentsAPI/DeleteDocument?documentname=" + documentName + "&documentpath=" + documentPath + "&userid=" + userGuid + "&productid=" + productId + "&producttype=" + productType + "&documenttype=" + documentType,
            dataType: "html",
            contentType: "application/json; charset=utf-8",
            success: function(data) {
                $('.tooltip').remove();
                var str = documentType.split(' ').join('-');

                $("." + productId + "" + str).remove();
                //alert('success');
            },
            error: function(data) {
                $('.tooltip').remove();
                //   alert('failed');
            }
        });
    });

    function fallbackCopyTextToClipboard(text) {
        var textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            var successful = document.execCommand("copy");
            var msg = successful ? "successful" : "unsuccessful";
            console.log("Fallback: Copying text command was " + msg);
        } catch (err) {
            console.error("Fallback: Oops, unable to copy", err);
        }

        document.body.removeChild(textArea);
    }

    // Copy Text
    function copyTextToClipboard(text) {
        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(
            function() {
                console.log("Async: Copying to clipboard was successful!");
            },
            function(err) {
                console.error("Async: Could not copy text: ", err);
            }
        );
    }

    document.querySelectorAll(".js-copy-bob-btn").forEach(btn => {
        btn.addEventListener("click", () => {
            toast('Link copied')
            copyTextToClipboard(window.location.hostname + "" + btn.getAttribute('data-document-path'));
        });
    });
})

// $(function () { hideLoader(); });