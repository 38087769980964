document.addEventListener('DOMContentLoaded', function() {

    if (document.getElementsByClassName("logon-panel-password-retrieval")[0]) {
        var z = document.getElementsByClassName("logon-panel-password-retrieval")[0];
        z.style.display = "none";
        if (getCookie("redirectForgotten") == "1") {
            forgottenPasswordShowForm();
            document.cookie = "redirectForgotten=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        }
    }
})

const forgottenPasswordShowForm = () => {
    var x = document.getElementsByClassName("login__form__wrapper")[0];
    var z = document.getElementsByClassName("logon-panel-password-retrieval")[0];

    if (x.style.display === "none") {
        x.style.display = "block";
        z.style.display = "none";
    } else {
        x.style.display = "none";
        z.style.display = "block";
    }
    z.style.opacity = "1";
}


const getCookie = cname => {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

const forgottenPasswordFromSignUp = () => {
    window.location.href = "/My-Digitron/Login";
    setCookie("redirectForgotten","1","1");
}

const setCookie = (cname, cvalue, exdays) => {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
