const stickyHeader = () => {
  if (document.querySelector(".product-type-table")) {
    const mql = window.matchMedia("(max-width: 991px)")
    const body = document.querySelector(".product-type-table")

    const scrollingContent = body.querySelector("#scrollingcontent")

    const beforeEl = body.querySelector(
      "#scrollingcontent .scrollingcontentblock:first-child"
    )

    const table = body.querySelector("table").cloneNode(true)
    table.classList.add("table-sticky")

    scrollingContent.insertBefore(table, beforeEl)

    const stickyTable = body.querySelector("table.table-sticky")

    const stickyTableTD = stickyTable.querySelectorAll(
      "tbody tr:first-child td"
    )
    const stickyTableTH = stickyTable.querySelectorAll(
      "thead tr:first-child th"
    )
    const allTHEAD = document.querySelectorAll("thead")
    const allTBODY = document.querySelectorAll("tbody")

    let stickyTableTHArr = []
    window.stickyTableTDArr = []

    // Calc for tbody td
    stickyTableTD.forEach((td) => {
      const compStyles = window.getComputedStyle(td)
      const width = compStyles.getPropertyValue("width")
      const attribute = td.getAttribute("data-auto-width")
      if (attribute) {
        window.stickyTableTDArr.push(width)
      }
    })

    stickyTableTH.forEach((td) => {
      const compStyles = window.getComputedStyle(td)
      const width = compStyles.getPropertyValue("width")
      const attribute = td.getAttribute("data-auto-width")
      if (attribute) {
        stickyTableTHArr.push(width)
      }
    })

    allTHEAD.forEach((thead) => {
      const ths = thead.querySelectorAll("tr:first-child th")

      ths.forEach((th, idx) => {
        const attribute = th.getAttribute("data-auto-width")
        if (attribute == "true") {
          th.style.width = stickyTableTHArr[idx]
          th.style.minWidth = stickyTableTHArr[idx]
          th.style.maxWidth = stickyTableTHArr[idx]
        }
      })
    })

    // Set width for tbody td
    allTBODY.forEach((tbody) => {
      const trs = tbody.querySelectorAll("tr")
      trs.forEach((tr) => {
        const tds = tr.querySelectorAll("td")
        tds.forEach((td, idx) => {
          const attribute = td.getAttribute("data-auto-width")
          if (attribute == "true") {
            td.style.width = stickyTableTDArr[idx]
            td.style.minWidth = stickyTableTDArr[idx]
            td.style.maxWidth = stickyTableTDArr[idx]
          }
        })
      })
    })

    const secondTable = scrollingContent.querySelector(
      'div[data-page="1"] table thead'
    )
    secondTable.remove()

    stickyTable.classList.add("hideTbody")

    const fixedHeader = () => {
      window.addEventListener("scroll", (e) => {
        const scrollPos = window.scrollY
        if (scrollPos > 120) {
          stickyTable.classList.remove("position-sticky")
          stickyTable.classList.add("position-absolute")
          stickyTable.classList.add("isFixed")
          stickyTable.style.top = scrollPos - 230 + "px"
          if (window.innerWidth <= 1382) {
            stickyTable.style.top = scrollPos - 250 + "px"
          }
          if (window.innerWidth <= 1199) {
            stickyTable.style.top = scrollPos - 180 + "px"
          }
          if (window.innerWidth <= 896) {
            stickyTable.style.top = scrollPos - 215 + "px"
          }
          if (window.innerWidth <= 768) {
            stickyTable.style.top = scrollPos - 50 + "px"
          }
        } else {
          stickyTable.classList.add("position-sticky")
          stickyTable.classList.remove("position-absolute")
          stickyTable.classList.remove("isFixed")
          if (window.innerWidth <= 991) {
            //stickyTable.style.top = "0px"
          } else {
            //stickyTable.style.top = "96px"
          }
        }
      })
    }

    fixedHeader()

    const mediaQuery = (e) => {
      if (e.matches) {
        fixedHeader()
      } else {
        stickyTable.classList.add("position-sticky")
        stickyTable.classList.remove("position-absolute")
        stickyTable.classList.remove("isFixed")
        //stickyTable.style.top = "96px"
      }
    }

    mql.addListener((e) => mediaQuery(e))
  }
}

document.addEventListener("DOMContentLoaded", (e) => {
  stickyHeader()
})
