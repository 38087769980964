let filesArr = new Array();
let fileNamesArr = new Array();

document.addEventListener('DOMContentLoaded', () => {
    columnsSorting();
    initElements();
    preventProductsDropdown();
    changeDropdownActiveItem();
    searchInput();
    setFiltersUrlParams();
    changeWindowLocation();
    showHideProfileForms();
    hoverOpenPosition();
    uploadedFileName();
    dragDrop();
    insertIconIntoLabel();
    changeElOrder();
    wrapLabelInput();
    changeSubmitBtnPosition();
    manipulationWithLabel();
    //changeIconOnSaveIcon();
    //keepFilterDropdownsOpen();
    addNewReturnItem();
    rmaReuqestSubmit();
    hoverOnLabel();
    window.onscroll = function () { onScrollSticky() };
    btnDocument();
})

function initElements() {
    $('[data-toggle="tooltip"]').tooltip()

    $('#menuNavbar, #filtering, #cat-links').on('shown.bs.collapse', function(e) {
        if (e.target.classList.contains('multi-collapse')) {
            return
        } else {
            $('.overlay').css({ 'visibility': 'visible', 'opacity': '1' });
            // $('body').css({ 'overflow': 'hidden'});
        }
    })
    $('#menuNavbar, #filtering, #cat-links').on('hidden.bs.collapse', function(e) {
        if (e.target.classList.contains('multi-collapse')) {
            return;
        } else {
            $('.overlay').css({ 'visibility': 'hidden', 'opacity': '0' });
            // $('body').css({ 'overflow': 'auto'});
        }
    })
}

function searchInput() {
    const forms = document.querySelectorAll('.search-form');
    const brand = document.querySelector('.navbar-brand');
    const toggler = document.querySelector('.navbar-toggler');
    const accountIcons = document.querySelectorAll('.account-item ')
    const smallScreen = '(max-width: 1199px)';
    const mediaQuery = window.matchMedia(smallScreen);

    forms.forEach(form => {
        form.querySelector('.show-search').addEventListener('click', e => {
            e.preventDefault();

            if ($('.search-form.active .form-control')[0] && !$('.search-form.active .form-control')[0].TextBoxWrapper._isWatermarked) {
                $('.active .searchBox input[type="submit"]').click();
            }

            form.classList.add('active')
            form.querySelector('.form-control').style.setProperty('display', 'inline-block', 'important');
            form.querySelector('.close-search').style.setProperty('display', 'inline-block', 'important');
            form.querySelector('.form-control').setAttribute("autofocus", ""); 
            form.querySelector('.form-control').autofocus;

            if (document.querySelector('.slogan')) {
                document.querySelector('.slogan').classList.remove('d-xl-flex')
            }

            const windowWidthChange = () => {
                
                if (mediaQuery.matches) {
                  
                    brand.style.setProperty('display', 'none');
                    accountIcons.forEach(icon => {
                        icon.style.setProperty('display', 'none' , 'important');
                    });
                    toggler.style.setProperty('display', 'none');
                    form.classList.remove('ml-auto');
                    form.querySelector('.input-group').classList.add('w-100');
                }
            }
            mediaQuery.addListener(windowWidthChange());
        });

        // Close Search
        form.querySelector('.close-search').addEventListener('click', e => {
            let homeSmallScreen = '(max-width: 991px)';
            let homeMediaQuery = window.matchMedia(homeSmallScreen);

            e.preventDefault();

            form.classList.remove('active')
            form.querySelector('.form-control').style.setProperty('display', 'none');
            form.querySelector('.close-search').style.setProperty('display', 'none');
            if (document.querySelector('.slogan')) {
                document.querySelector('.slogan').classList.add('d-xl-flex')
            }
            // add media query
            const windowWidthChangeTwo = () => {
                if (mediaQuery.matches) {
                    brand.style.removeProperty('display');
                    accountIcons.forEach(icon => {
                        icon.style.removeProperty('display');
                    });
                    if(!document.querySelector('.navbar-home')) {
                        toggler.style.setProperty('display', 'inline-block', 'important');
                    }
                    form.classList.remove('w-100');
                    form.classList.add('ml-auto');
                    form.querySelector('.input-group').classList.remove('w-100');
                }
            }
            const winWidthCheck = () => {
                if (mediaQuery.matches) {
                    toggler.style.setProperty('display', 'inline-block');
                }
            }

            mediaQuery.addListener(windowWidthChangeTwo());
            homeMediaQuery.addListener(winWidthCheck())
        });
    })
}

function onScrollSticky() {
    const navigation = document.querySelector("nav.navbar");
    let isHomeNav = false;
    let homeNav;
    if (document.querySelector("nav.navbar.navbar-home")) {
        homeNav = document.querySelector("nav.navbar.navbar-home");
        isHomeNav = true
    }

    if (window.pageYOffset > 80) {
        navigation.classList.add('sticky')
        if (document.getElementById("other-categories") && window.screen.width < 1200) {
            document.getElementById("other-categories").classList.add("sticky");
        }
        if (isHomeNav) {
            homeNav.querySelector('.navbar-brand').classList.remove('d-lg-none')
            homeNav.classList.add('sticky')
        }
    } else {
        navigation.classList.remove('sticky')
        if (document.getElementById("other-categories")) {
            document.getElementById("other-categories").classList.remove("sticky");
        }
        if (isHomeNav) {
            homeNav.querySelector('.navbar-brand').classList.add('d-lg-none')
            homeNav.classList.remove('sticky')
        }
    }
}

function preventProductsDropdown() {
    if (document.querySelector('.product .dropdown-toggle')) {
        const drops = document.querySelectorAll('.product .dropdown-toggle');

        drops.forEach(drop => {
            drop.addEventListener('mouseover', (e) => {
                if (e.target.classList.contains('btn__icon')) {
                    drop.style.boxShadow = "0 2px 10px 0 rgba(245, 0, 11, 0.17)"
                }
            })
            drop.addEventListener('mouseout', (e) => {
                if (e.target.classList.contains('btn__icon')) {
                    drop.style.boxShadow = "unset"
                }
            })
        })
    }
}

function changeDropdownActiveItem() {
    if (document.querySelector('.search-for-words')) {
        const btns = document.querySelectorAll('.search-for-words');

        btns.forEach(function (item) {
            item.addEventListener('click', function (e) {
                e.preventDefault(); 
                if(e.target.classList.contains('dropdown-item')) {
                    item.querySelector('.dropdown-toggle').innerHTML = e.target.textContent;
                }
            })
        })
    }
}


function setFiltersUrlParams() {    
    if (document.querySelector('.filters__form')) {
        let historyCount = 0;
        if (window.location.search) {
            let url = window.location.search.substr(1);
            // params = url.split("&");
        }        
        $('body').on('click', '.filters__form input[type="checkbox"]', function (e) {
            showLoader();
            let pageParam = window.location.search.substr(1).split('&').filter(item => item.indexOf('orderdirection=')>=0 || item.indexOf('orderfield=')>=0);
            $('#currentpg').val(1);
            let filter="";
            $(".filters__form__item .multi-collapse").toArray().forEach(function(item){
                let options = $(item).find("input[type=checkbox]:checked").toArray();
                if(options.length>0){
                    let condition = options.map(option=> $(option).data("value")).join('||');                    
                    if(filter!=="") filter+="&";
                    filter += `${item.id}=${condition}`;
                }
            });

            let url =  decodeURI(`?${filter}`);

            if (pageParam.length > 0) {
                url = filter === '' ? decodeURI(url + pageParam.join('&')) : decodeURI(url + '&' + pageParam.join('&'))
            };

            history.replaceState('', '', url);
            historyCount += 1;
            $('#next_data_page').val(2);
            $('#currentpg').val(1);
            $('#next_data_url').val('');
            $('#prev_data_url').val('');
            next_data_cache = null;
            prev_data_cache = null;
            $('#next_data_url').val(updateURLParameter(window.location.href, 'page', '2'));
            $('.PagerControl').remove();
        });
        window.addEventListener('popstate', function (event) {
            window.history.go(-historyCount)
        }, false);

        $('body').on('click', '.filters__form__item > a[data-toggle="collapse"]', function(e) {
            if ($(this).prev() && $(this).prev().is("input")) {
                showLoader();
                $(this).prev().click();
            }
        });
    }
}

function changeWindowLocation () {
    if (document.querySelector('.about-item') || document.querySelector('.news__story')) {
        const aboutItems = document.querySelectorAll('.about-item');
        const stroryItems = document.querySelectorAll('.news__story');
        getDataHref(aboutItems)
        getDataHref(stroryItems)
    }
}

function getDataHref(items) {
    items.forEach(item => {
        item.addEventListener('click', (e) => {
            let attr = item.getAttribute('data-href');
            if (e.target.tagName !== 'A' && attr !== null) {
                window.location = attr;
            }
        })
    })
}

function showHideProfileForms() {
    if (document.querySelector('#change-password') || document.querySelector('#edit-info')) {
        const pswrd = document.querySelector('#change-password');
        const editInfo = document.querySelector('#edit-info');
        const pswrdForm = document.querySelector('#change-password-group');
        const editInfoForm = document.querySelector('#personal-info-group');
        const profileCard = document.querySelector('#profile-card');
        const actionBtns = document.querySelectorAll('.action-btn')

        pswrd.addEventListener('click', (e) => {
            e.preventDefault();
            profileCard.classList.add('d-none');
            pswrdForm.classList.toggle('d-none')
        })
        editInfo.addEventListener('click', (e) => {
            e.preventDefault();
            editInfoForm.classList.toggle('d-none')
            profileCard.classList.add('d-none');
        })
        
        actionBtns.forEach(btn => {
            btn.addEventListener('click', () => {
                if (!pswrdForm.classList.contains('d-none') || !editInfoForm.classList.contains('d-none')) {
                    profileCard.classList.remove('d-none');
                    pswrdForm.classList.add('d-none');
                    editInfoForm.classList.add('d-none');
                }
            })
        })
    }
}

// Hover class for open positions
function hoverOpenPosition() {
    if (document.querySelector('.open-position')) {
        const positions = document.querySelectorAll('.open-position')

        positions.forEach(position => {
            position.addEventListener('mouseover', (e) => {
                if (e.target.classList.contains('apply-position')) {
                    position.classList.add('active')
                }
            })
            position.querySelector('.apply-position').addEventListener('mouseleave', (e) => {
                position.classList.remove('active')
            })
        })

    }
    if (document.querySelector('.product-types')) {
        const positions = document.querySelectorAll('.product-overview__item')

        positions.forEach(position => {
            position.addEventListener('mouseover', (e) => {
                if (e.target.classList.contains('view-product')) {
                    position.classList.add('active')
                }
            })
            position.querySelector('.view-product').addEventListener('mouseleave', (e) => {
                position.classList.remove('active')
            })
        })

    }
}

// Disaply uploaded file name
function uploadedFileName() {
    if (document.querySelector('.custom-file')) {
        const customFile = document.querySelector('.custom-file input[type="file"]')
        customFile.addEventListener('change', e => {
           let fileName =  e.target.files[0].name
           document.querySelector('.custom-file-name').innerText = fileName;
        })
    }
}

// Drag&Drop File
function dragDrop() {
    if (document.querySelector('.upload-file__area')) {
        const area = document.querySelector('.upload-file__area');
        const h4 = area.querySelector('h4');
        const file = area.querySelector('input[type="file"]')
        let html = '';
        let ul = document.querySelector('#uploaded-files')

        area.addEventListener('dragover', e => {
            area.style.borderColor = '#bf232a';
        })
        area.addEventListener('dragleave', e => {
            area.style.borderColor = 'rgba(191, 35, 42, 0.4)';
        })

        file.addEventListener('click', e => {
            e.target.value = ''
        })

        file.addEventListener('change', e => {
            let file = e.target.files;
            let existFiles = new Array();
            html = '';
            if (file.length == 0) {
                h4.innerHTML = 'Drag and drop files here or <label for="file">Choose file</label> to upload';
                area.style.borderColor = 'rgba(191, 35, 42, 0.4)';
            } else {
                // Push to Array
                for (let i = 0; i < file.length; i++) {
                    fileNamesArr.includes(file[i].name) ? existFiles.push(file[i].name) && toast(`You've already uploaded ${existFiles.join(', ')}`) : fileNamesArr.push(file[i].name) && filesArr.push(file[i]);
                    console.log(filesArr)
                }

                // Push to UI
                fileNamesArr.forEach((name, i) => {
                    html += `
                    <li class="pt-3 d-flex align-items-center">
                        <a href="#" class="pr-3" data-name="${name}">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25" viewBox="0 0 22 25">
                                <path fill="none" fill-rule="evenodd" stroke="#BF232A" stroke-linecap="round" stroke-linejoin="round" d="M0 4h22M2.5 24h16V4h-16v20zm4-20h8V1h-8v3zm0 3.5v12m4-12v12m4-12v12"></path>
                            </svg>
                        </a>
                        ${name}
                    </li>`
                })
                ul.innerHTML = html;
            }
        })

        // Remove from UI and array
        ul.addEventListener('click', e => {
            e.preventDefault();
            const removeItem = (name, type) => {
                let idx = fileNamesArr.indexOf(name);
                if (idx > -1) {
                    fileNamesArr.splice(idx, 1);
                    type === 'svg' ? e.target.parentElement.parentElement.remove() :  e.target.parentElement.parentElement.parentElement.remove()
                }
                filesArr = filesArr.filter(item => item.name != name)
            }
            if (e.target.tagName == "svg") {
                let name = e.target.parentElement.getAttribute('data-name');
                removeItem(name, 'svg')
            }
            if (e.target.tagName == "path") {
                let name = e.target.parentElement.parentElement.getAttribute('data-name');
                removeItem(name, 'path')
            }
        })
    }
}

function hoverOnLabel() {
    if (document.querySelector('.card__question__answers__form')) {
        const cards = document.querySelectorAll('.card--question')
        cards.forEach(card => {
            card.querySelectorAll('.rating__label').forEach(item => {
                item.addEventListener('mouseover', (e) => {
                    card.classList.add('card--active')
                })
                item.addEventListener('mouseleave', (e) => {
                    card.classList.remove('card--active')
                })
            })
        })

    }
}

function getSearchValue() {
    const url = window.location;
    const queryString = url.search;
    const searchParams = new URLSearchParams(queryString); 
    const searchText = searchParams.get('searchtext');

    return searchText;
}


// Insert <i> into label
function insertIconIntoLabel() {
    if (document.querySelector('.card__question__answers__form label')) {
        const labels = document.querySelectorAll('.card__question__answers__form label');

        labels.forEach(label => {
            label.innerHTML = `<i class="rating__icon rating__icon--star fa fa-star"></i>`
        })
    }
}

// Reorder label and input position
function changeElOrder() {
    if ( document.querySelector('.rating-group span label')) {
        document.querySelectorAll('.rating-group span label').forEach((l) => {
            l.parentNode.insertBefore(l, l.previousElementSibling);
        });

        document.querySelectorAll('.rating-group span').forEach(el => {
            if (el.querySelector('input[type="radio"]:checked') === null) {
                el.classList.add('clear-form')
            }

            el.querySelectorAll('input[type="radio"]').forEach(item => {
                item.addEventListener('input', e => {
                    el.classList.remove('clear-form')
                })
            })
        })
    }
}

// Wrap Label Adn Input to div
function wrapLabelInput() {
    if (document.querySelector('.radio-form-card')) {
        let getSpan = document.querySelector('.radio-form-card span')
        let div = document.createElement('DIV');
        div.classList.add('custom-control', 'custom-radio', 'custom-control-inline')

        getSpan.querySelectorAll('label').forEach(label => label.classList.add('custom-control-label'))
        
        let firstLabel = getSpan.getElementsByTagName('label')[0].outerHTML
        let firstInput =  getSpan.getElementsByTagName('input')[0].outerHTML
        let secondLabel = getSpan.getElementsByTagName('label')[1].outerHTML
        let secondInput =  getSpan.getElementsByTagName('input')[1].outerHTML

        let newHtml = `
            <div class="custom-control custom-radio custom-control-inline">
                ${firstInput}
                ${firstLabel}
            </div>
            <div class="custom-control custom-radio custom-control-inline">
                ${secondInput}
                ${secondLabel}
            </div>
        `;

        getSpan.innerHTML = newHtml;
    }
}

// Change submit btn position in login form
function changeSubmitBtnPosition() {
    if (document.querySelector('.submit-form')) {
        const wrapper = document.querySelector('.submit-form');
        const submitBtn = document.querySelector('input[type="submit"].RegisterButton').parentElement;
        submitBtn.classList.add('d-flex');
        wrapper.insertBefore(submitBtn, wrapper.childNodes[0])
    }
}

function manipulationWithLabel() {
    const errorInput = document.querySelectorAll('.editing-form-control-nested-control.Error input');
    let id;

    document.querySelectorAll(`.EditingFormLabel`).forEach(label => {
        label.classList.add('text-right', 'mb-0', 'pr-3', 'w-100');
    })

    errorInput.forEach(input => {
        id = input.getAttribute('id')
        if(document.querySelector(`.EditingFormLabel[for="${id}"]`)) {
            document.querySelector(`.EditingFormLabel[for="${id}"]`).classList.add('Error')
        }
        if (input.parentElement.parentElement.querySelector('label.mobile-label')) {
            input.parentElement.parentElement.querySelector('label.mobile-label').classList.add('Error');
        }
    })
}

// Product Table Change Icon on Save Item
// function changeIconOnSaveIcon() {
//     if (document.querySelector('.save-item')) {
//         const btnsSave = document.querySelectorAll('.save-item');
//         const btnsSaved = document.querySelectorAll('.item-saved');
//         btnsSave.forEach(btn => {
//             btn.addEventListener('click', e => {
//                 e.preventDefault();
                
//                 btn.parentElement.querySelector('.item-saved').classList.remove('d-none')
//                 btn.classList.add('d-none')
//                 toast('You successfully saved item!', '/My-Digitron/Saved-Items', 'All saved items');
//             })
//         })

//         btnsSaved.forEach(btn => {
//             btn.addEventListener('click', e => {
//                 e.preventDefault();
//                 btn.parentElement.querySelector('.save-item').classList.remove('d-none')
//                 btn.classList.add('d-none')
//             })
//         })
//     }
// }

// Custom toast message
function toast(msg, linkUrl, linkText, type) {
    if (document.querySelector('.alert-custom')) {
        document.querySelector('.alert-custom').remove();
    }

    const toast = document.createElement('DIV')
    const body = document.querySelector('body');

    if (type === 'success') {
        toast.classList.add('alert', 'alert-custom', 'alert-success', 'alert-dismissibl', 'fade', 'show')
    } else if (type === 'danger') {
        toast.classList.add('alert', 'alert-custom', 'alert-danger', 'alert-dismissibl', 'fade', 'show')
    } else {
        toast.classList.add('alert', 'alert-custom', 'alert-warning', 'alert-dismissibl', 'fade', 'show')
    }

    if ((linkUrl === undefined || linkText === undefined) && (type !== 'success' && type !== 'danger')) {
        toast.innerHTML = `
        <strong>${msg}</strong>
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
        `;
    } else if (type === 'success' || type === 'danger') {
        toast.innerHTML = `
        ${msg}
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
        `;
    } else {
        toast.innerHTML = `
        <strong>${msg}!</strong> <a href="${linkUrl}" class="alert-link">${linkText}</a>.
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
        `;
    }
    body.appendChild(toast)
    let msgTimeDisplay = 5000;

    if (toast.innerText.split(' ').length > 10) {
        msgTimeDisplay = 10000
    }

    setTimeout(e => {
        toast.remove()
    }, msgTimeDisplay)

    toast.addEventListener('click', e => {
        if (e.target.tagName == 'SPAN') {
            body.removeChild(toast)
        }
    });
    
}

// Start Preloaders
// if (document.querySelector('.preloader')) {
//     const loader = document.querySelector('.preloader')

//     window.addEventListener('load', () => {
//         loader.style.opacity = 1;
//     })
    
//     window.addEventListener('beforeunload', () => {
//         loader.style.opacity = 1;
//         loader.style.display = 'flex';
//     })
    
//     window.onload = function() {
//         const fadeEffect = setInterval(function () {
//             if (loader.style.opacity > 0) {
//               loader.style.opacity -= 0.1;
//             } else {
//               clearInterval(fadeEffect);
//               loader.style.display = 'none';
//             }
//           }, 30);
//     }
// }

// RMA Table add new item

function addNewReturnItem() {
    let rmaAddedItems = [];
    const url = "/rmarequestapi/RMARequestAPI/GetSavedItems"
    let tableBody = document.querySelector('.rma-table');
    // Append to html table new items
    const insertHtml = (partNumber, quantity, reason) => {
        let typeOfInsert;
        document.querySelectorAll('.added-items').length > 0 ? (tableBody = document.querySelector('.added-items'),typeOfInsert = 'beforebegin') : typeOfInsert = 'beforeEnd';
        tableBody.insertAdjacentHTML(`${typeOfInsert}`, `
        <div class="items-group__table__row-body added-items">
            <div class="row">
                <div class="col-md-3 d-flex align-items-center mobile-row">
                    <div class="column d-md-none">
                        Part Number
                    </div>
                    <div class="column part-number-added">
                        <span class="rma-part-number">${partNumber}</span>
                        <input class="form-control form-part-number d-none" type="text">
                    </div>
                </div>
                <div class="col-md-2 d-flex align-items-center mobile-row">
                    <div class="column d-md-none">
                        Quantity
                    </div>
                    <div class="column quantity-added">
                
                        <span class="rma-quantity">${quantity}</span>
                    <input class="form-control form-quantity d-none" type="number" value="1">
                    </div>
                </div>
                <div class="col-md-5 col-xl-6 d-flex align-items-center mobile-row">
                    <div class="column d-md-none">
                        Reason for return (Specifics of operation)
                    </div>
                    <div class="column reason reason-added">
                        <span class="rma-reason">${reason}</span>
                        <input class="form-control form-reason d-none" type="text">
                    </div>
                </div>
                <div class="col-md-2 col-xl-1 d-flex align-items-center mobile-row">
                    <div class="column d-md-none">
                        Action
                    </div>
                    <div class="column d-flex trash">
                        <svg class="edit-item" version="1.1" width="22" height="25" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            width="528.899px" height="528.899px" viewBox="0 0 528.899 528.899" style="enable-background:new 0 0 528.899 528.899;"
                            xml:space="preserve">
                                <path fill="#bf232a" d="M328.883,89.125l107.59,107.589l-272.34,272.34L56.604,361.465L328.883,89.125z M518.113,63.177l-47.981-47.981
                                    c-18.543-18.543-48.653-18.543-67.259,0l-45.961,45.961l107.59,107.59l53.611-53.611
                                    C532.495,100.753,532.495,77.559,518.113,63.177z M0.3,512.69c-1.958,8.812,5.998,16.708,14.811,14.565l119.891-29.069
                                    L27.473,390.597L0.3,512.69z"/>
                        </svg>
                        <svg class="save-item d-none" width="22" height="25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                                <path fill="#bf232a" d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0
                                    c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7
                                    C514.5,101.703,514.499,85.494,504.502,75.496z"/>
                        </svg>

                        <svg class="remove-item" xmlns="http://www.w3.org/2000/svg" width="22" height="25" viewBox="0 0 22 25">
                            <path fill="none" fill-rule="evenodd" stroke="#BF232A" stroke-linecap="round" stroke-linejoin="round" d="M0 4h22M2.5 24h16V4h-16v20zm4-20h8V1h-8v3zm0 3.5v12m4-12v12m4-12v12"></path>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
        `)
    }
    
    if (tableBody) {
        let icon;
        // Remove/Edit/Save Items
        tableBody.addEventListener('click', e => {
            icon = e.target;
            // Edit Mode Func
            const editMode = (parentEl) => {
                let elm = parentEl.parentElement.parentElement.parentElement;

                elm.querySelectorAll('span').forEach(span => {
                    span.classList.add('d-none');
                })

                elm.querySelectorAll('input')[0].value = elm.querySelectorAll('span')[0].textContent;
                elm.querySelectorAll('input')[1].value = elm.querySelectorAll('span')[1].textContent;
                elm.querySelectorAll('input')[2].value = elm.querySelectorAll('span')[2].textContent;

                elm.querySelector('input.form-part-number').classList.remove('d-none');
                elm.querySelector('input.form-quantity').classList.remove('d-none');
                elm.querySelector('input.form-reason').classList.remove('d-none');
            }
            // Save Mode Func
            const saveMode = (parentEl) => {
                let elm = parentEl.parentElement.parentElement.parentElement;
                elm.querySelectorAll('span').forEach(span => {
                    span.classList.remove('d-none');
                })
                elm.querySelectorAll('input').forEach(span => {
                    span.classList.add('d-none');
                })
                rmaAddedItems.find (key => key.PartNumber == elm.querySelectorAll('span')[0].innerText).PartNumber = elm.querySelector('input.form-part-number').value;
                elm.querySelectorAll('span')[0].textContent = elm.querySelector('input.form-part-number').value;
                elm.querySelectorAll('span')[1].textContent = elm.querySelector('input.form-quantity').value;
                elm.querySelectorAll('span')[2].textContent = elm.querySelector('input.form-reason').value;
                
            }
            // Edit event
            if (icon.classList.contains('edit-item')) {
                // Show/Hide Icons
                icon.classList.add('d-none')
                icon.parentElement.querySelector('.save-item').classList.remove('d-none')
                // Show Input
                editMode(icon);
            }
            // Save event
            if (icon.classList.contains('save-item')) {
                // Show/Hide Icons
                let inputQuantity = icon.parentElement.parentElement.parentElement.querySelector('.form-quantity');
                let inputPartNumber = icon.parentElement.parentElement.parentElement.querySelector('.form-part-number');
                let inputReason = icon.parentElement.parentElement.parentElement.querySelector('.form-reason');
                let errorMessage = '';

                if(inputPartNumber.value.trim()===''){
                    inputPartNumber.parentElement.classList.add('Error');
                    errorMessage = 'Please fill part number';
                }else{
                    inputPartNumber.parentElement.classList.remove('Error');
                }

                if(inputQuantity.value.trim()===''){
                    inputQuantity.parentElement.classList.add('Error');
                    errorMessage = 'Please fill quantity';
                }else{
                    inputQuantity.parentElement.classList.remove('Error');
                }

                if(inputReason.value.length>100){
                    inputReason.parentElement.classList.add('Error');
                    errorMessage = 'Too long message. Max symbols: 100';
                }else{
                    inputReason.parentElement.classList.remove('Error');
                }

                if(inputPartNumber.value.trim()==='' || inputQuantity.value.trim()==='' || inputReason.value.length>100){
                    toast(errorMessage);
                    e.preventDefault();
                }else{
                    icon.parentElement.querySelector('.edit-item').classList.remove('d-none')
                    icon.classList.add('d-none')
                    saveMode(icon);
                }
            }
            // Remove event
            if (icon.classList.contains('remove-item')) {
                let deletedItem =  icon.parentElement.parentElement.parentElement.querySelector('span').textContent;
                rmaAddedItems = rmaAddedItems.filter(prop => prop.PartNumber != deletedItem);
                icon.closest('.remove-item').parentElement.parentElement.parentElement.parentElement.remove();
            }
        })
    }
    
    // Added items from back-end
    if (document.querySelector('.upload-saved-parts')) {
        let uploadedSavedItemsBtn = document.querySelector('.upload-saved-parts a');

        uploadedSavedItemsBtn.addEventListener('click', e => {
            e.preventDefault();
            fetch(url)
            .then(res => res.json())
            .then(data => {
                // Compare exist array and array from backend
                let tempArr = [];
                tempArr = data.filter(resItem => {
                    return(
                        !rmaAddedItems.some(frontItem => frontItem.PartNumber === resItem.PartNumber)
                    )
                })
                tempArr.forEach(item => {
                    rmaAddedItems.push(item);
                })
                // Show items in UI
                tempArr.forEach(item => {
                    insertHtml(item.PartNumber, '1', '')
                })
                
            })
            .catch(err => {
                toast("You don’t have saved items yet")
            })
        })
    }
    if(document.querySelector('.save-item:not(.btn-document)')){
        const saveBtn = document.querySelector('.save-item:not(.btn-document)');

        saveBtn.addEventListener('click', e =>{
            let editPn = document.querySelector('.form-part-number input');
            if(editPn.value.trim()===''){
                editPn.parentElement.classList.add('Error');
                errMsg = 'Part Number is required';
            }else{
                editPn.parentElement.classList.remove('Error')
            }
            
            if (editPn.value.trim()==='') {
                toast(errMsg)
                e.preventDefault();
            }
        })
    }

    // Add items on page
    if (document.querySelector('.add-return-item')) {
        const addBtn = document.querySelector('.add-return-item');
        
        addBtn.addEventListener('click', e => {
            let partNumber = document.querySelector('.validate-partNumber input');
            let quantity = document.querySelector('.validate-quantity input');
            let reason = document.querySelector('.reason input');
            let errMsg = '';
           
            if (quantity.value.trim() === '') {
                quantity.parentElement.classList.add('Error')
                errMsg = 'Quality is required';
            } else {
                quantity.parentElement.classList.remove('Error')
            }
            if (partNumber.value.trim() === '') {
                partNumber.parentElement.classList.add('Error')
                errMsg = 'Part Number is required';
            } else {
                partNumber.parentElement.classList.remove('Error')
            }

            if(reason.value.length>100){
                reason.parentElement.classList.add('Error')
                errMsg = 'Too long message. Max symbols: 100'
            }else{
                reason.parentElement.classList.remove('Error')
            }

            if (partNumber.value.trim() === '' || quantity.value.trim() === '' || reason.value.length>=100) {
                toast(errMsg)
                e.preventDefault();
            }

            if (errMsg.length === 0) {
                if (!document.querySelector('.suggestions').classList.contains('d-none')) {
                    document.querySelector('.suggestions').classList.add('d-none')
                }

                if (!rmaAddedItems.some(frontItem => frontItem.PartNumber === partNumber.value)) {
                    rmaAddedItems.push({
                        PartNumber: partNumber.value,
                        quantity: quantity.value,
                        reason: reason.value
                    })
                    insertHtml(partNumber.value, quantity.value, reason.value)
                } else {
                    toast('You have already added this item')
                }

                partNumber.value = '';
                quantity.value = '';
                reason.value = '';
            }
        })
    }
}

// Hold RMA REQUEST Submit
function rmaReuqestSubmit() {
    if (document.querySelector('.rma-request-submit')) {
        const submitBtn = document.querySelector('.rma-request-submit');
        const personalData = document.querySelector('.saved-parts-form');
        const filesInput = document.querySelector('#file');
        let formData = new FormData();
        let personalDataObj = new Object();
        let returnedItems = new Array();

        const clearData = () => {
            // Clear inputs and data
            document.querySelectorAll('input:not([disabled="true"]):not([type="button"])').forEach(input => {
                input.value = '';
                personalDataObj = {};
                returnedItems = [];
                formData = new FormData();
            })

            // Remove added rows
            if (document.querySelector('.added-items')) {
                document.querySelectorAll('.added-items').forEach(item => {
                    item.remove();
                })
            }
            filesArr = new Array();
            fileNamesArr = new Array();

            // Change markup on drag&drop field
            document.querySelector('#uploaded-files').innerHTML = '';
            submitBtn.classList.remove('loading');
        }

        submitBtn.addEventListener('click', e => {
            // Validate Form
            let name = document.querySelector('input.validate-name');
            let company = document.querySelector('input.validate-company');
            let email = document.querySelector('input.validate-email');
            let invoiceNumber = document.querySelector('input.validate-invoice');
            let date = document.querySelector('input.validate-date');
            let purchaseNumber = document.querySelector('input.validate-purchase');
            let errorMsgsArr = [];

            function labelClass(el, reset) {
                let id = el.getAttribute('id')
                if (reset) {
                   document.querySelector(`label[for*=${id}]`).classList.remove('Error');
                   el.parentElement.classList.remove('Error')
                   el.parentElement.parentElement.querySelector('label').classList.remove('Error')
                } else {
                    e.preventDefault();
                    el.parentElement.parentElement.querySelector('label').classList.add('Error')
                    document.querySelector(`label[for*=${id}]`).classList.add('Error')
                    el.parentElement.classList.add('Error');
                    toast(errorMsgsArr[errorMsgsArr.length-1])
                }
            }

            if (purchaseNumber.value.trim() === '') {
                errorMsgsArr.push('Purchase Order Number is required')
                labelClass(purchaseNumber);
            } else {
                labelClass(purchaseNumber, true);
            }

            if (date.value.trim() === '') {
                errorMsgsArr.push('Invoice Date is required')
                labelClass(date);
            } else {
                labelClass(date, true);
            }

            if (invoiceNumber.value.trim() === '') {
                errorMsgsArr.push('Invoice Number is required')
                labelClass(invoiceNumber);
            } else {
                labelClass(invoiceNumber, true);
            }

            if (email.value.trim() === '' || !validator.isEmail(email.value)) {
                errorMsgsArr.push('Email is invalid')
                labelClass(email);
            } else {
                labelClass(email, true);
            }

            if (company.value.trim() === '') {
                errorMsgsArr.push('Company is required')
                labelClass(company);
            } else {
                labelClass(company, true);
            }

            if (name.value.trim() === '') {
                errorMsgsArr.push('Name is required')
                labelClass(name);
            } else {
                labelClass(name, true);
            }

            // Submit Form
            if (errorMsgsArr.length === 0) {
                if (!document.querySelector('.added-items')) {
                    toast('Add one or more returned item')
                } else if (grecaptcha.getResponse().length === 0) {
                    toast('Please check the captcha!')
                } else {
                    submitBtn.classList.add('loading');
                    personalDataObj = {
                        company: personalData.querySelector('.company input').value,
                        name: personalData.querySelector('.name input').value,
                        email: personalData.querySelector('.email input').value,
                        phone: personalData.querySelector('.phone input').value,
                        invoiceNumber: personalData.querySelector('.invoice-number input').value,
                        invoiceDate: new Date(personalData.querySelector('.invoice-date input').value).toLocaleDateString('en-US'),
                        purchaseOrderNumber: personalData.querySelector('.purchase-order-number input').value
                    }
                    if (document.querySelector('.added-items')) {
                        document.querySelectorAll('.added-items').forEach(item => {
                            returnedItems.push({
                                partNumber: item.querySelector('.part-number-added span').innerText,
                                quantity: item.querySelector('.quantity-added span').innerText,
                                reasonForReturn: item.querySelector('.reason-added span').innerText,
                            })
                        })
                    } else {
                        returnedItems = [];
                    }

                    formData.append('PersonalDataObj', JSON.stringify(personalDataObj))
                    formData.append('ReturnedItems', JSON.stringify(returnedItems))

                    for (let i = 0; i < filesArr.length; i++) {
                        let sfilename = filesArr[i].name;
                        formData.append(sfilename, filesArr[i]);
                    }
                    
                    $.ajax({
                        type: "POST",
                        catch: false,
                        contentType: false,
                        processData: false,
                        data : formData,
                        url: "/rmarequestapi/RMARequestAPI/SaveRMARequest",
                        success: function(data) {
                            toast('<strong>Thank you for your request</strong> We will review your request within one to two business days.', undefined, undefined, 'success');
                            clearData()
                            grecaptcha.reset();
                            setTimeout(() => {
                                document.querySelector('.alert-success').remove();
                            }, 3000)
                        },
                        error: function(data) {
                            personalDataObj = {};
                            returnedItems = [];
                            formData.delete('PersonalDataObj');
                            formData.delete('ReturnedItems');
                            for (let i = 0; i < filesArr.length; i++) {
                                let sfilename = filesArr[i].name;
                                formData.delete(`${sfilename}`);
                            }
                            submitBtn.classList.remove('loading');
                            toast(`${data.statusText}`, undefined, undefined, 'danger')
                        }
                    });
                }
            }
        })
    }
}


// Autocomplete
const fetchSuggestions = (value) => {
    const url = '/rmarequestapi/RMARequestAPI/Predictive';

    fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            partNumber: value
        })
    })
    .then(res => res.json())
    .then(data => {
        let ul = document.querySelector('.suggestions')
        if (data.length != 0) {
            ul.innerHTML = '';
            data.forEach((partNumber, i) => {
                ul.innerHTML += `
                    <li>${partNumber.partnumber}</li>
                `;
                ul.classList.remove('d-none');
            })
        } else {
            ul.classList.add('d-none')
        }
    })
    .catch(err => console.log(err))
}

document.addEventListener('DOMContentLoaded', () => {
    if (document.querySelector('.validate-partNumber input')) {
        const input = document.querySelector('.validate-partNumber input')

        input.addEventListener('input', (e) => {
            let value = e.target.value;
            if (value.length >= 3) {
                setTimeout(() =>{
                    fetchSuggestions(value, setSuggestion(input))
                }, 500)
            } else {
                document.querySelector('.suggestions').classList.add('d-none')
            }
        })
    }
})

// Get and set suggestion value
const setSuggestion = (input) => {
    let suggestions = document.querySelector('.suggestions');
    suggestions.addEventListener('click', e => {
        if (e.target.tagName == 'LI') {
            input.value = e.target.innerText;
            suggestions.classList.add('d-none')
        }
    })

}

// Show Loader function
const showLoader = () => {
    const form = document.querySelector('.filters__form ');
    form.classList.add('load-filter')
}

// Hide Loader function
const hideLoader = () => {
    const form = document.querySelector('.filters__form ');
    form.classList.remove('load-filter')
}

// Success message after form submit
document.addEventListener('DOMContentLoaded', e => {
    if (document.querySelector('span.InfoLabel')) {
       //let msg = document.querySelector('span.InfoLabel');
       //msg.remove();
       //toast(`<strong>Thank you!</strong> ${msg.textContent}.`, undefined, undefined, 'success'); 
        document.querySelector(".section-title").classList.add('d-none'); 
        document.querySelector(".submit-resume").classList.add('message-request'); 
        document.querySelector(".submit-resume").classList.remove('submit-resume'); 
    }
    if (document.querySelector('#filtering')) {
        let allCollapse = document.querySelectorAll('#filtering .btn-accordion');
        let id;
        let arrayOfIds = [];

        allCollapse.forEach(item => {
            id = item.getAttribute('href').substr(1);
            if (document.querySelectorAll(`#${id} input:checked`).length != 0) {
                document.querySelectorAll(`#${id}`).forEach(collapse => {
                    collapse.classList.add('show')
                })
                arrayOfIds.push(id);
                document.querySelector('#filtering input[type=hidden]').value = arrayOfIds.join(',');
                item.setAttribute('aria-expanded', true)
            }
        })
    }
})

// Start Product Table sorting
const columnsSorting = () => {
    
    // Clear filter params fro url and refresh page
    document.querySelector('main').addEventListener('click', e => {
        if (e.target.classList.contains('clear-filters') || e.target.parentElement.classList.contains('clear-filters')) {
            window.location.search = ''
        }
        if (e.target.classList.contains('filtering-btn')) {
            if (document.querySelector('.clear-filters')) {
                document.querySelector('.filters__form__item').classList.add('clear-btn-exist')
            }
        }
    });

    if (document.querySelector('.arrow-up') || document.querySelector('.arrow-down')) {
        let table = document.querySelector('main');
        let url = new URL(window.location.href);
        let urlParams = new URLSearchParams(url.search);

        // Show Hide arrow button
        let ascOrDesc = urlParams.get('orderdirection');
        let columnName = urlParams.get('orderfield');
        if (ascOrDesc !=null && columnName !=null) {
            const showArrowBtn = document.querySelector(`small[data-orderdirection="${ascOrDesc}"][data-orderfield="${columnName}"]`)
            showArrowBtn.classList.add('invisible')
            const hideArrowBtn = document.querySelector(`small[data-orderfield="${columnName}"]:not([data-orderdirection="${ascOrDesc}"])`)
            hideArrowBtn.classList.remove('invisible')
        }
        
        // Sort Table after arrow clicked
        table.addEventListener('click', e => {
            url = new URL(window.location.href);
            urlParams = new URLSearchParams(url.search);
            let directionAttr = '';
            let fieldAttr = '';
            let btn = e.target;

            // Get params form button
            if (btn.classList.contains('arrow-up') || btn.classList.contains('arrow-down')) {
                directionAttr = btn.getAttribute('data-orderdirection')
                fieldAttr = btn.getAttribute('data-orderfield')
            }

            // Manipulation with url params
            if (directionAttr != '' && fieldAttr != '') {
                if (urlParams.has('orderfield') && urlParams.has('orderdirection')) {
                    // If exist set new value
                    urlParams.set('orderfield', fieldAttr);
                    urlParams.set('orderdirection', directionAttr);

                    window.location.search = decodeURI(urlParams.toString());
                } else {
                    // Add to params
                    urlParams.append("orderfield", fieldAttr);
                    urlParams.append("orderdirection", directionAttr);
                    window.location.search = decodeURI(urlParams.toString());
                }
                // Redirect
            }
        })
    }
}

// Focus Input Search
function FocusShow() {
    const input = document.querySelector('.navbar-collapse .search-form input[type="text"]');
    input.setAttribute('tabindex', 0)
    setTimeout(() => input.focus(), 10)
}

// Navbar items manipulation
document.addEventListener('DOMContentLoaded', e => {
    const menuManipulation = () => {
        document.querySelector('nav.navbar').addEventListener('click', e => {
            let navItem = e.target;
            if (navItem.classList.contains('dropdown-toggle')) {
                navItem.setAttribute('data-toggle', 'dropdown')
                let a = document.createElement('A')
                let parentEl = e.target.parentElement.querySelector('.dropdown-menu');
                a.className = 'dropdown-item'
                a.setAttribute('href', e.target.getAttribute('href'))
                a.textContent = e.target.textContent;
                parentEl.insertBefore(a, e.target.parentElement.querySelector('.dropdown-item:first-child'))
            }
        })
    }

    function windowResize(x) {
        if (x.matches) {
            menuManipulation();
        }
    }
    
    const size = window.matchMedia("(max-width: 767px)")
    windowResize(size)
    size.addListener(windowResize)

    // Tootip on Product Type Table
    if (document.querySelector('.product-type-table')) {
        let mediaQuery = window.matchMedia('(max-width: 1024px)');
        // Show tooltip on click for touch devices
        if (mediaQuery) {
            document.addEventListener('click', e => {
                if (e.target.tagName == 'svg' || e.target.tagName == 'IMG') {
                    $(e.target).tooltip('show')
                    setTimeout(() => $(e.target).tooltip('hide'), 3000)
                }
                if (e.target.classList.contains('info-link-details')) {
                    $(e.target).tooltip('show')
                    setTimeout(() => $(e.target).tooltip('hide'), 3000)
                }
            })
        } else {
            // Desktop click
            document.addEventListener('mouseover', e => {
                if (e.target.tagName == 'svg' || e.target.tagName == 'IMG') {
                    $(e.target).tooltip('show')
                }
                if (e.target.classList.contains('info-link-details')) {
                    $(e.target).tooltip('show')
                }
            })
        }
    }
})

// Prevent Default btn-document
const btnDocument = () => {
    if (document.querySelector('.product-info__actions')) {
        document.querySelector('.product-info__actions').addEventListener('click', e => {
            if (e.target.classList.contains('btn-document') || e.target.classList.contains('btn-document-delete')) {
                e.preventDefault();
            }
        })
    }
}